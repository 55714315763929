import NewSsh from '@/layouts/Stack/components/NewSsh';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  data() {
    return {
      data: {},
      timerId: '',
      link: {},
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {
    // quota() {
    //   return this.$store.getters['moduleStack/aviableSpaseVolume'];
    // },
    // projectId() {
    //   return this.$store.getters['moduleStack/projectId'];
    // },
  },
  methods: {
    newSsh: function (instance) {
      return new Promise(() => {
        const selfName = 'newSsh';
        const that = this;
        let time;
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: NewSsh,
          closeOnBackdrop: false,
          props: {
            // quota: this.quota,
            // size: 1,
            instance: instance,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              // console.log(data);
              instance = data;
              // console.log(instance);
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
            ready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            // centered: true,
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  this.modal.text = null;
                  this.modal.component = BaseLoader;
                  this.modal.closable = false;
                  this.modal.footer = false;
                  time = Date.now();
                  this.isProcessing = true;
                  // console.log(instance);
                  this.createOsKeyPair(instance)
                    .then(async data => {
                      // console.log(data);
                      if (data && data.keypair) {
                        // this.$modals.close({ name: selfName });
                        this.$store.dispatch('moduleStack/fetchOsKeypair').then(() =>
                          setTimeout(
                            () => {
                              this.modal.component = null;
                              this.modal.closable = true;
                              this.modal.text = this.$t('sure.key');
                              this.modal.footer = {
                                centered: true,
                                confirm: {
                                  props: { title: this.$t('sure.close') },
                                  // props: { title: 'Закрыть' },
                                  on: {
                                    click: () => {
                                      this.$modals.close();
                                      this.$store.dispatch('moduleStack/fetchOsKeypair');
                                    },
                                  },
                                },
                              };
                            }
                            // delay
                            // setTimeout(() => {
                            //   this.showResModal('Ключ добавлен'), 1000;
                            // })
                          )
                        );
                        //
                      } else {
                        // console.log(data);
                      }
                    })
                    .catch(e => {
                      // console.log(e);
                      // console.log(
                      //   e.response.data.badRequest.message,
                      //   e.response.data.conflictingRequest.message
                      // );
                      this.$modals.close();
                      this.showResModal(
                        (e.response.data &&
                          e.response.data.badRequest &&
                          e.response.data.badRequest.message) ||
                          (e.response.data &&
                            e.response.data.conflictingRequest &&
                            e.response.data.conflictingRequest.message)
                      );
                      this.showError(e);
                    });
                  // const delay = Date.now() - time < 1000 ? 1000 : 0;
                  // setTimeout(() => {
                  //   this.modal.component = null;
                  //   this.modal.closable = true;
                  //   this.modal.text = this.$t('modal.sure.success');
                  //   this.modal.footer = {
                  //     centered: true,
                  //     confirm: {
                  //       props: { title: this.$t('modal.sure.close') },
                  //       on: {
                  //         click: () => {
                  //           this.$modals.close();
                  //           this.$store.dispatch('moduleStack/fetchOsKeypair');
                  //         },
                  //       },
                  //     },
                  //   };
                  // }, delay);
                },
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                this.$modals.close();
              },
            },
          },
        });
      });
    },
    createOsKeyPair(params) {
      const payload = {
        keypair: {
          name: params.name,
          public_key: params.key,
        },
      };
      return this.$store.dispatch('moduleStack/createOsKeyPair', payload);
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
